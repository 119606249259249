<template>
  <v-chip
    :color="roleColor"
    :text-color="roleTextColor"
    v-bind="$attrs"
  >
    {{ roleText }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: { type: null, default: null }
  },
  computed: {
    roleColor() {
      if (this.value === 'owner') return 'deep-orange'
      if (this.value === 'admin') return 'blue'
      return 'light-grey'
    },
    roleTextColor() {
      if (this.value === 'owner') return 'white'
      if (this.value === 'admin') return 'white'
      return 'black'
    },
    roleText() {
      if (this.value === 'owner') return 'владелец'
      if (this.value === 'admin') return 'админ'
      if (this.value === 'agent') return 'консультант'
      return this.value
    }
  }
}
</script>

<style scoped>

</style>
