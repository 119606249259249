<template>
  <v-container class="pa-4">
    <apollo-query
      :query="require('@/graphql/user-list.gql')"
      :variables="{ limit: $options.pageSize }"
    >
      <template v-slot="{ result: { loading, error, data }, query }">
        <v-toolbar flat>
          <v-icon>mdi-dots-vertical</v-icon>
          <v-slide-x-transition mode="out-in">
            <v-btn v-if="selected.length" text color="red" @click="deleteDocs(query)">
              <v-icon>mdi-delete</v-icon>
              <v-badge color="red" :content="selected.length" :value="selected.length > 1" inline>
                Удалить
              </v-badge>
            </v-btn>
          </v-slide-x-transition>
          <v-btn icon @click="refreshUsers(query)"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-spacer />
          <v-btn color="primary" @click="onUserAddClick">Добавить</v-btn>
        </v-toolbar>

        <v-row class="result apollo">
          <v-col>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="(data || {}).users"
              :items-per-page="5"
              item-key="_id"
              :loading="loading"
              loading-text="Получение данных..."
              :no-data-text="error ? 'ошибка получения данных' : 'нет данных'"
              show-select
              class="app-user-table"
              @click:row="onRowClick"
            >
              <template v-slot:item.approved="{ item }">
                <v-badge
                  bordered
                  :color="`${item.approved ? 'green' : 'red'} accent-4`"
                  dot
                  offset-x="10"
                  offset-y="10"
                >
                  <v-avatar size="36">
                    <v-icon size="36">mdi-account-circle</v-icon>
                  </v-avatar>
                </v-badge>
              </template>
              <template v-slot:item.email="{ item }">
                <div class="pt-2 pb-2">
                  <div>{{ item.name }}</div>
                  <div class="grey--text">{{ item.email }}</div>
                </div>
              </template>
              <template v-slot:item.role="{ item }">
                <v-role-chip :value="item.role" small />
              </template>
              <template v-slot:item._actions="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link :disabled="item.role === 'owner' || item._id === userId">
                      <v-list-item-title @click="deleteDocs(query, [item._id])">
                        Удалить
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="addUserDialog"
          persistent
          width="auto"
          max-width="400"
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Новый пользователь</span>
            </v-card-title>
            <v-card-text>
              <new-user-form
                @after:insert="users => onUserAdded(users, query)"
                @cancel:click="addUserDialog = false"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </apollo-query>
  </v-container>
</template>

<script>
import GQL_DELETE_USER from '@cpanel/graphql/user-delete.mutation.gql'
import NewUserForm from './components/new-user-form'
import VRoleChip from './components/v-role-chip'

export default {
  components: { VRoleChip, NewUserForm },
  pageSize: 10,
  data() {
    return {
      selected: [],
      addUserDialog: false,
      userModel: null,
      headers: [
        {
          value: 'approved',
          text: 'Статус',
          width: '96px'
        },
        {
          text: 'Имя',
          align: 'start',
          sortable: true,
          value: 'email',
          width: '240px',
          divider: true
        },
        { text: 'Роль', value: 'role' },
        {
          value: '_actions',
          text: 'Действия',
          width: '120px',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    userId() {
      return (this.$store.getters['user/user'] || {})._id || null
    }
  },
  methods: {
    async refreshUsers(query) {
      await query.refetch()
      this.$toast.success('Данные обновлены успешно')
    },
    async onUserAdded(users, query) {
      this.addUserDialog = false
      this.$nextTick(() => { query.refresh() })
      this.$toast.success('Данные добавлены успешно')
    },
    async deleteDocs(query, idsToDelete = null) {
      const ids = idsToDelete || this.selected.filter(el => (el.role !== 'owner')).map(user => user._id)
      if (ids.includes(this.userId)) {
        this.$toast.error('Нельзя удалить свой аккаунт')
        return
      }
      try {
        await this.$apollo.mutate({ mutation: GQL_DELETE_USER, variables: { ids: ids }})
        this.selected = []
        await this.refreshUsers(query)
      } catch (e) {
        this.$toast.error(e.toString())
      }
    },
    onUserAddClick() {
      this.addUserDialog = true
    },
    onRowClick(item) {
      this.$router.push(`/user/${item._id}`)
    }
  }
}
</script>

<style scoped>
  .app-user-table >>> tbody > tr {
    cursor: pointer;
  }
</style>
