var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4"},[_c('apollo-query',{attrs:{"query":require('@/graphql/user-list.gql'),"variables":{ limit: _vm.$options.pageSize }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")]),_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[(_vm.selected.length)?_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.deleteDocs(query)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_c('v-badge',{attrs:{"color":"red","content":_vm.selected.length,"value":_vm.selected.length > 1,"inline":""}},[_vm._v(" Удалить ")])],1):_vm._e()],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.refreshUsers(query)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onUserAddClick}},[_vm._v("Добавить")])],1),_c('v-row',{staticClass:"result apollo"},[_c('v-col',[_c('v-data-table',{staticClass:"app-user-table",attrs:{"headers":_vm.headers,"items":(data || {}).users,"items-per-page":5,"item-key":"_id","loading":loading,"loading-text":"Получение данных...","no-data-text":error ? 'ошибка получения данных' : 'нет данных',"show-select":""},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"bordered":"","color":((item.approved ? 'green' : 'red') + " accent-4"),"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{attrs:{"size":"36"}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-account-circle")])],1)],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.email))])])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-role-chip',{attrs:{"value":item.role,"small":""}})]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","disabled":item.role === 'owner' || item._id === _vm.userId}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteDocs(query, [item._id])}}},[_vm._v(" Удалить ")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"auto","max-width":"400","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.addUserDialog),callback:function ($$v) {_vm.addUserDialog=$$v},expression:"addUserDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Новый пользователь")])]),_c('v-card-text',[_c('new-user-form',{on:{"after:insert":function (users) { return _vm.onUserAdded(users, query); },"cancel:click":function($event){_vm.addUserDialog = false}}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }