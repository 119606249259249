<template>
  <v-scrollable-layout :header-height="48">
    <template v-slot:header>
      <v-toolbar dense class="elevation-1">
        <v-toolbar-title>
          <v-btn small text @click="$router.go(-1)"><v-icon size="16" class="pr-1">mdi-arrow-left</v-icon> назад</v-btn>
        </v-toolbar-title>
        <v-toolbar-items class="d-flex justify-center align-center" style="width: 100%; padding-right: 74px;">
          <div class="headline">{{ (getUser || {}).name }}</div>
        </v-toolbar-items>
      </v-toolbar>
    </template>
    <v-container fluid class="mt-6">
      <v-row justify="center">
        <v-col cols="12" class="col-sm-8 col-md-6">
          <edit-user-form
            class="pa-6"
            :value="getUser"
            @cancel:click="onCancel"
            @after:save="afterSave"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-scrollable-layout>
</template>

<script>
import VScrollableLayout from '@cpanel/components/scrollable-layout'
import GQL_QUERY_USER from '@cpanel/graphql/user-get.gql'
import EditUserForm from './components/edit-user-form'

export default {
  components: { VScrollableLayout, EditUserForm },
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  apollo: {
    getUser: {
      query: GQL_QUERY_USER,
      variables() { return { user: { _id: this.userId }} }
    }
  },
  data() {
    return {}
  },
  methods: {
    afterSave() {
      this.$router.push('/user/list')
    },
    onCancel() {
      this.$router.push('/user/list')
    }
  }
}
</script>

<style scoped>

</style>
