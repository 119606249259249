<template>
  <v-container>
    <apollo-mutation
      :mutation="mutation"
      :variables="{ user: userModel }"
      :update="updateCache"
      @done="onSave"
      @error="onError"
    >
      <template v-slot="{ mutate, loading }">
        <v-form v-if="model" ref="form" v-model="valid" lazy-validation @submit.stop="mutate">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.name"
                :rules="[rules.required, rules.username]"
                label="Имя"
                :counter="64"
                required
                @keyup.esc="$emit('cancel:click')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="model.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                required
                @keyup.esc="$emit('cancel:click')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="model.password"
                :rules="[rules.required, rules.password]"
                label="Пароль"
                required
                :type="hidePassword ? 'password' : 'text'"
                :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                @click:append="hidePassword = !hidePassword"
                @keyup.esc="$emit('cancel:click')"
              />
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center">
                <div class="flex-grow-1 flex-shrink-1">Права администратора</div>
                <div class="flex-grow-0 flex-shrink-0" style="width: 80px;">
                  <v-switch v-model="roleAdmin" :label="roleAdmin ? 'Да' : 'Нет'" />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-space-around mt-6">
            <v-btn text :disabled="loading" @click.stop="onCancel">Отмена</v-btn>
            <v-btn color="primary" :disabled="!valid || loading" :loading="loading" @click.stop="mutate">
              Создать
            </v-btn>
          </v-row>
        </v-form>
      </template>
    </apollo-mutation>
  </v-container>
</template>

<script>
import GQL_QUERY_USERS from '@cpanel/graphql/user-list.gql'
import GQL_ADD_USER from '@cpanel/graphql/user-add.mutation.gql'
import rules from '@cpanel/includes/form-rules'

export default {
  data() {
    return {
      valid: false,
      model: { name: null, email: null, password: null },
      hidePassword: true,
      roleAdmin: false,
      rules
    }
  },
  computed: {
    userModel() {
      return {
        name: this.model.name,
        email: this.model.email,
        role: this.roleAdmin ? 'admin' : 'agent',
        password: this.model.password
      }
    },
    mutation() {
      return GQL_ADD_USER
    }
  },
  mounted() {
    this.clearForm()
  },
  methods: {
    clearForm() {
      this.model = { name: null, email: null, password: null }
      this.roleAdmin = false
      this.valid = true
      this.$refs.form.reset()
    },
    onSave({ data }) {
      this.clearForm()
      this.$emit('after:save', data.addUser)
    },
    onCancel() {
      this.clearForm()
      this.$emit('cancel:click')
    },
    onError(err) {
      this.$store.dispatch('dialog/error', err)
    },
    updateCache(store, { data: response }) {
      try {
        const user = response.addUser
        const data = store.readQuery({ query: GQL_QUERY_USERS })
        data.users.push(user)
        this.$emit('after:insert', data.users)
        store.writeQuery({ query: GQL_QUERY_USERS, data })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
