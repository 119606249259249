<template>
  <v-card class="white" style="margin-top: 70px;">
    <v-card-text class="pt-0">
      <apollo-mutation
        :mutation="mutation"
        :variables="{ user: userModel }"
        :update="updateCache"
        @done="onSave"
        @error="onError"
      >
        <template v-slot="{ mutate, loading }">
          <v-form v-if="model" ref="form" v-model="valid" lazy-validation @submit.stop="mutate">
            <v-row>
              <v-col cols="4" />
              <v-col cols="4" class="d-flex justify-center" style="position: relative">
                <div style="height: 64px; position: absolute; top: -64px;">
                  <div class="blue-grey lighten-4 d-flex justify-center elevation-1" style="width: 128px; height: 128px; border-radius: 50%;">
                    <v-icon size="96">mdi-account</v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <v-btn disabled x-small text color="red">Сменить пароль</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="model.name"
                  :rules="[rules.required, rules.username]"
                  label="Имя"
                  :counter="64"
                  required
                  @keyup.esc="$emit('cancel:click')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="model.email"
                  label="Email"
                  disabled
                  :rules="[rules.required, rules.email]"
                  append-icon="mdi-lock"
                />
              </v-col>
              <v-col cols="12">
                <div class="d-flex align-center">
                  <div class="flex-grow-1 flex-shrink-1">Права администратора</div>
                  <div class="flex-grow-0 flex-shrink-0" style="width: 80px;">
                    <v-switch v-model="roleAdmin" :disabled="model.role==='owner'" :label="roleAdmin ? 'Да' : 'Нет'" />
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-space-around mt-6">
              <v-btn text :disabled="loading" @click.stop="onCancel">Отмена</v-btn>
              <v-btn color="primary" :disabled="!valid || loading" :loading="loading" @click.stop="mutate">
                Сохранить
              </v-btn>
            </v-row>
          </v-form>
        </template>
      </apollo-mutation>
    </v-card-text>
  </v-card>
</template>

<script>
import GQL_QUERY_USERS from '@cpanel/graphql/user-list.gql'
import GQL_UPDATE_USER from '@cpanel/graphql/user-update.mutation.gql'
import rules from '@cpanel/includes/form-rules'

export default {
  props: {
    value: { type: null, default: () => (null) }
  },
  data() {
    return {
      valid: false,
      model: null,
      roleAdmin: false,
      rules
    }
  },
  computed: {
    userModel() {
      const user = {
        _id: this.model._id,
        name: this.model.name,
        role: (this.model.role === 'owner') ? 'owner' : (this.roleAdmin ? 'admin' : 'agent')
      }
      return user
    },
    mutation() {
      return GQL_UPDATE_USER
    }
  },
  watch: {
    value(newVal) {
      this.setModel(newVal)
    }
  },
  created() {
    this.setModel()
  },
  mounted() {
    this.setModel(this.value)
  },
  methods: {
    setModel(val = null) {
      if (!val && this.$refs.form) this.$refs.form.reset()
      val = val || {}
      this.model = {
        _id: val._id || null,
        name: val.name || '',
        email: val.email || '',
        role: val.role || 'agent',
        approved: val.approved || null
      }
      this.roleAdmin = ['admin', 'owner'].includes(val.role)
      this.valid = true
    },
    onSave({ data }) {
      const user = data.updateUser
      this.setModel()
      this.$emit('after:save', user)
    },
    onCancel() {
      this.setModel()
      this.$emit('cancel:click')
    },
    onError(err) {
      this.$store.dispatch('dialog/error', err)
    },
    updateCache(store, { data: response }) {
      try {
        const user = response.updateUser
        const data = store.readQuery({ query: GQL_QUERY_USERS })

        const userIdx = data.users.findIndex(p => (p._id === user._id))
        if (userIdx >= 0) data.users[userIdx] = user
        this.$emit('after:update', data.users)

        store.writeQuery({ query: GQL_QUERY_USERS, data })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>
</style>
